import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query GetUserInfo {
    getUserInfo {
      userId
      userType
      roles
      familyName
      firstName
      givenName
      lastName
      permissions {
        applicationName
        permissions
      }
      region
      preferredName
      sub
      distributorCodes
    }
  }
`;
