import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import * as S from './TooltipWrapper.styles';

const TooltipWrapper = ({ children, tooltipText, isDisabled = false, ...rest }) => {
  if (isDisabled) {
    return children;
  }
  return (
    <S.StyledTooltip
      istitle={+!!tooltipText}
      title={
        tooltipText && (
          <Typography whiteSpace="pre-line" variant="label2" component="span">
            {tooltipText}
          </Typography>
        )
      }
      arrow={!!tooltipText}
      {...rest}
    >
      {children}
    </S.StyledTooltip>
  );
};

TooltipWrapper.propTypes = {
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
};

export default TooltipWrapper;
