import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import NotificationsPanelView from 'views/NotificationsPanelView/NotificationsPanelView';
import NavBar from 'components/NavBar/NavBar';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { usePathSegments } from './utils';

const Root = () => {
  const navProps = usePathSegments();

  return (
    <>
      <Box height="100vh" pt={navProps?.subnavTitle === null ? '3.5rem' : '6.5rem'}>
        <Box position="fixed" width="100%" top={0} zIndex={9999}>
          <NavBar navProps={navProps} />
        </Box>

        <Suspense fallback={<LoadingSpinner fullScreen />}>
          <Outlet />
        </Suspense>
      </Box>

      <NotificationsPanelView />
    </>
  );
};

export default Root;
