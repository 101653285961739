import React from 'react';
import { Box } from '@mui/material';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import PropTypes from 'prop-types';
import messages from './errorPage.config.json';

const ErrorPage = ({ type = 'problems' }) => {
  const typeMessages = messages.find((msg) => msg.type === type) || messages[0];
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <ErrorCard
        message={typeMessages.message}
        details={typeMessages.details}
        subDetails={typeMessages.subDetails}
      />
    </Box>
  );
};

ErrorPage.propTypes = {
  type: PropTypes.oneOf(['404', 'problems', 'authorization']),
};

export default ErrorPage;
