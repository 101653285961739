import styled from '@emotion/styled';
import { Switch } from '@mui/material';
import { Chip } from 'cprime-foundational-components';

export const StyledFilterSwitch = styled(Switch)(({ theme }) => ({
  height: '2rem',
  width: '3rem',
  padding: '0.5rem',
  '& .MuiSwitch-track': {
    border: `0.1rem solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.background.surfacePaper,
    borderRadius: '0.688rem',
    height: '1rem',
    width: '2rem',
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: '0.625rem',
    height: '0.625rem',
    margin: '0.125rem',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 24 24"><path fill="${theme.palette.primary.dark}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
  },
  '& .MuiSwitch-switchBase.Mui-checked > .MuiSwitch-thumb': {
    backgroundColor: theme.palette.background.surfacePaper,
  },
  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(16px)',
  },
}));

export const StyledChip = styled(Chip)`
  margin-left: auto;
`;
