import React, { createContext, useContext, useState, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const SharedBookmark = createContext({});

const SharedBookmarkProvider = () => {
  const [sharedSubId, setSharedSubId] = useState('');
  const [sharedGroupName, setSharedGroupName] = useState('');

  const contextValue = useMemo(
    () => ({ sharedGroupName, sharedSubId, setSharedGroupName, setSharedSubId }),
    [sharedSubId, sharedGroupName],
  );

  return (
    <SharedBookmark.Provider value={contextValue}>
      <Outlet />
    </SharedBookmark.Provider>
  );
};

export default SharedBookmarkProvider;

export const useSharedBookmark = () => useContext(SharedBookmark);
