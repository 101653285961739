import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';

const AlertMessage = ({
  title = 'Error',
  msg = 'There was an issue loading the data. If this error persists - please contact the iTX team.',
  severity = 'error',
  fullWidth,
}) => (
  <Alert severity={severity} sx={{ width: fullWidth ? '100%' : 'auto' }}>
    <AlertTitle>{title}</AlertTitle>
    {msg}
  </Alert>
);

AlertMessage.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  severity: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default AlertMessage;
