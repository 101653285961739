import { useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();

  return (to) => {
    if (to.startsWith('http')) {
      window.location.href = to;
    } else {
      navigate(to);
    }
  };
};
