import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, ListItem } from '@mui/material';

const CascadingFiltersListItem = ({ isChecked, isIndeterminate, setCheckbox, itemText }) => (
  <ListItem sx={{ padding: '0 1rem', height: '1.5rem' }} dense>
    <Checkbox
      onClick={() => setCheckbox()}
      checked={isChecked}
      indeterminate={isIndeterminate}
      disableRipple
      size="small"
      sx={{
        '&.MuiButtonBase-root': {
          height: '1.5rem',
        },
      }}
      label={itemText}
    />
    {itemText}
  </ListItem>
);

CascadingFiltersListItem.propTypes = {
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  setCheckbox: PropTypes.func.isRequired,
  itemText: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
};

export default CascadingFiltersListItem;
