import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, IconButton, Link, Stack, Typography } from '@mui/material';
import {
  Info as InfoIcon,
  DoDisturbOn as DoDisturbOnIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  CancelRounded as CancelRoundedIcon,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import { Notification } from 'contexts/Notification.context';
import { getFormattedTimeElapsed } from 'utils/formatFns';
import { notificationColorMap } from 'utils/colorMappers';

const NotificationCard = ({ id, title, subtitle, type, timestamp, isRead }) => {
  const { readNotification, unreadNotification } = useContext(Notification);

  const getAlertProps = () => {
    const alertProps = {};

    switch (type) {
      case 'info':
        alertProps.severity = 'info';
        alertProps.icon = <InfoIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'success':
        alertProps.severity = 'success';
        alertProps.icon = <CheckCircleIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'error':
        alertProps.severity = 'error';
        alertProps.icon = <DoDisturbOnIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'warning':
        alertProps.severity = 'warning';
        alertProps.icon = <WarningIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'notFound':
        alertProps.severity = 'error';
        alertProps.icon = <CancelRoundedIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
    }
    return alertProps;
  };

  const handleReadNotification = () => {
    readNotification(id);
  };

  const handleUnreadNotification = () => {
    unreadNotification(id);
  };

  return (
    <Alert
      sx={{
        borderLeft: `0.25rem solid ${isRead ? 'transparent' : notificationColorMap[type]}`,
        borderRadius: 0,
        bgcolor: 'white',
        position: 'relative',
      }}
      components={{
        CloseIcon: isRead ? RadioButtonUnchecked : RadioButtonChecked,
      }}
      action={
        <IconButton
          color="info"
          disableRipple
          sx={{ '&:hover, &:focus': { backgroundColor: 'background.surfaceLight' } }}
          onClick={isRead ? handleUnreadNotification : handleReadNotification}
        >
          {isRead ? (
            <RadioButtonUnchecked fontSize="small" />
          ) : (
            <RadioButtonChecked fontSize="small" />
          )}
        </IconButton>
      }
      {...getAlertProps()}
    >
      <Typography
        variant="caption2"
        color="text.secondary"
        sx={{
          position: 'absolute',
          right: '3rem',
        }}
      >
        {getFormattedTimeElapsed(timestamp)}
      </Typography>
      <AlertTitle sx={{ width: '21rem' }}>
        <Typography variant="body3" color="text.primary">
          {title}
        </Typography>
      </AlertTitle>
      <Stack direction="row">
        <Typography variant="label2" color="text.primary">
          {subtitle}&nbsp;
          <Link href="#" color="info.main" underline="hover" onClick={handleReadNotification}>
            Results
          </Link>
        </Typography>
      </Stack>
    </Alert>
  );
};

NotificationCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  timestamp: PropTypes.number,
  isRead: PropTypes.bool,
};

export default NotificationCard;
