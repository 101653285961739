import React from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ handleChange = () => {}, handleSearch, placeholder, value }) => {
  return (
    <Stack
      direction="row"
      alignItems="stretch"
      component="form"
      onChange={(e) => {
        e.preventDefault();
        const searchTerm = e.target.value;
        handleChange(searchTerm);
      }}
      onSubmit={(e) => {
        e.preventDefault();
        const searchTerm = e.target.elements[0].value;
        handleSearch(searchTerm);
      }}
      id="SearchBarForm"
    >
      <TextField
        value={value}
        variant="outlined"
        placeholder={placeholder}
        size="small"
        InputProps={{
          style: {
            height: '40px',
            paddingRight: '3px',
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" type="submit">
                <SearchIcon color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

SearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default SearchBar;
