import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Stack,
} from '@mui/material';
import { filter } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getSectionChipLabel } from './utils.js';
import * as S from './CascadingFiltersHeader.styles.jsx';

export const CascadingFiltersHeader = ({
  headerName,
  type,
  options,
  setOpenSection,
  openSection,
  setListSectionData,
  dealerChipLabel,
  seriesChipLabel,
  setCascadingFiltersLocal,
  cascadingFiltersLocal,
  children,
  ...cascadingFiltersObj
}) => {
  const openAccordion = () => setOpenSection(headerName === openSection ? null : headerName);

  const accordionOnClickHandler = () => {
    if (setListSectionData) {
      setListSectionData({ headerName, options });
    } else {
      openAccordion();
    }
  };

  const handleDelete = () => {
    let field;
    if (type === 'CascadingFiltersHeader') {
      field = cascadingFiltersObj.field;
    } else {
      field = options[0].field;
    }
    if (field) {
      const newCascadingFiltersLocal = filter(
        cascadingFiltersLocal,
        (filterLocal) => filterLocal.field !== field,
      );
      setCascadingFiltersLocal(newCascadingFiltersLocal);
    }
  };

  const sectionChipLabelHandler = () => {
    if (headerName === 'Dealer') {
      return dealerChipLabel.showCount ? dealerChipLabel.dealerCodeCount : 'ALL';
    }
    if (headerName === 'Series') {
      return seriesChipLabel.showCount ? seriesChipLabel.seriesCount : 'ALL';
    }
    return getSectionChipLabel({ headerName, options });
  };

  return (
    <Accordion
      square={true}
      disableGutters={true}
      expanded={headerName === openSection}
      onChange={() => accordionOnClickHandler()}
      sx={{
        '&.css-1betqn-MuiAccordionSummary-content': { margin: '0 0 35px' },
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        minHeight: '2.5rem',
        '&.MuiPaper-rounded': {
          boxShadow: 0,
          transition: 'none',
        },
        '&.MuiPaper-root': {
          boxShadow: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          type === 'CascadingFiltersHeader' ? (
            <IconButton sx={{ p: 0.25 }}>
              <ArrowForwardIcon sx={{ width: '1rem', height: '1rem' }} />
            </IconButton>
          ) : (
            <IconButton sx={{ p: 0.25 }}>
              <ExpandMoreIcon sx={{ width: '1rem', height: '1rem' }} />
            </IconButton>
          )
        }
        aria-controls="panel-content"
        id="panel-header"
        margin={0}
        sx={{
          minHeight: '2.5rem',
          height: '2.5rem',
          '& .MuiAccordionSummary-content': { minWidth: 0 },
          boxShadow: 0,
        }}
      >
        <Stack direction="row" alignItems="center" width="100%" gap={1}>
          <Typography
            variant="body4"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {headerName}
          </Typography>
          <S.StyledChip
            label={sectionChipLabelHandler()}
            onDelete={sectionChipLabelHandler() !== 'ALL' ? handleDelete : undefined}
            sx={{
              '&, *': {
                cursor: 'pointer',
              },
            }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 0 0.5rem' }}>{children}</AccordionDetails>
    </Accordion>
  );
};

CascadingFiltersHeader.propTypes = {
  headerName: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  setOpenSection: PropTypes.func,
  openSection: PropTypes.string,
  setListSectionData: PropTypes.func,
  dealerChipLabel: PropTypes.object,
  seriesChipLabel: PropTypes.object,
  setCascadingFiltersLocal: PropTypes.func,
  cascadingFiltersLocal: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
