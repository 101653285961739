import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { forIn } from 'lodash';

const LocalStorageContext = createContext();

export function useLocalStorage() {
  return useContext(LocalStorageContext);
}

const defaultValues = {
  pipelineFilters: {},
  pipelineCascadingFilters: {},
  customerRequestFPFilters: {},
  customerRequestFPRequest: {},
  customerRequestFilters: {},
};

export const LocalStorageProvider = ({ children }) => {
  const [data, setData] = useState(defaultValues);

  useEffect(() => {
    const dataNew = {};
    const storedData = localStorage.getItem('pipeline');
    const storedDataParsed = (storedData && JSON.parse(storedData)) || {};
    forIn(defaultValues, (value, key) => {
      dataNew[key] = storedDataParsed[key] || value;
    });
    setData(dataNew);
  }, []);

  const setLocalStorageData = (newData) => {
    localStorage.setItem('pipeline', JSON.stringify(newData));
    setData(newData);
  };

  const setPipelineFilters = (pipelineFilters) => setLocalStorageData({ ...data, pipelineFilters });

  const setCustomerRequestFilters = (customerRequestFilters) =>
    setLocalStorageData({ ...data, customerRequestFilters });

  const setCustomerRequestFPFilters = (customerRequestFPFilters, customerRequestFPRequest) =>
    setLocalStorageData({ ...data, customerRequestFPFilters, customerRequestFPRequest });

  const setPipelineCascadingFilters = (pipelineCascadingFilters) =>
    setLocalStorageData({ ...data, pipelineCascadingFilters });

  const clearLocalStorage = () => {
    localStorage.removeItem('pipeline');
    setData(null);
  };

  const providerValue = useMemo(
    () => ({
      data,
      clearLocalStorage,
      setPipelineFilters,
      setCustomerRequestFilters,
      setCustomerRequestFPFilters,
      setPipelineCascadingFilters,
    }),
    [data],
  );

  return (
    <LocalStorageContext.Provider value={providerValue}>{children}</LocalStorageContext.Provider>
  );
};

LocalStorageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
