import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO } from '../queries/GetUserInfo';

export const Roles = createContext({});

const RolesProvider = ({ children }) => {
  const { data, loading: isUserInfoLoading } = useQuery(GET_USER_INFO);

  const permissionsExist = (permissionNames) => {
    if (!data?.getUserInfo?.permissions) {
      return false;
    }
    const APPLICATION_NAME = 'E2E';

    const appPermissions = data.getUserInfo.permissions.find(
      (permission) => permission.applicationName === APPLICATION_NAME,
    );

    return (
      (appPermissions &&
        appPermissions.permissions?.some((permission) => permissionNames.includes(permission))) ||
      false
    );
  };

  const regionPermissions = () => {
    return data?.getUserInfo?.region;
  };

  const userInfo = () => {
    return data?.getUserInfo;
  };

  const contextValue = useMemo(
    () => ({ permissionsExist, regionPermissions, userInfo, isUserInfoLoading }),
    [permissionsExist, regionPermissions, userInfo, isUserInfoLoading],
  );

  return <Roles.Provider value={contextValue}>{children}</Roles.Provider>;
};

RolesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RolesProvider;
