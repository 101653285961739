import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import { useProfileContext } from './UserProfile.context';
import { PAGE_FILTER } from 'queries/PageFilter';

export const CascadingFilters = createContext({});

const getInitialCascadingFilters = (selectedUserProfile) =>
  get(selectedUserProfile, 'value.cascading') || [];

const CascadingFiltersProvider = ({ children }) => {
  const { selectedUserProfile, setFilters } = useProfileContext();
  const { data } = useQuery(PAGE_FILTER);

  const [cascadingFilters, setCascadingFilters] = useState(
    getInitialCascadingFilters(selectedUserProfile),
  );

  useEffect(() => {
    setCascadingFilters(getInitialCascadingFilters(selectedUserProfile));
  }, [selectedUserProfile]);

  const setCascadingFiltersHandler = (filters) => {
    setCascadingFilters(filters);

    // TODO: after removing filter management from profile, need to get back here and reevaluate if such approach is still needed
    setFilters({ filterType: 'cascading', filters });
  };

  const contextValue = useMemo(
    () => ({ cascadingFilters, data, setCascadingFilters: setCascadingFiltersHandler }),
    [cascadingFilters, data, setCascadingFiltersHandler],
  );

  return <CascadingFilters.Provider value={contextValue}>{children}</CascadingFilters.Provider>;
};

CascadingFiltersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CascadingFiltersProvider;

export const useCascadingFilterContext = () => useContext(CascadingFilters);
