import React, { Suspense, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import CascadingFilters from 'views/CascadingFilters/CascadingFilters';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

const CustomerRequests = () => {
  const [isShowCascadingFilters, setIsShowCascadingFilters] = useState(false);
  const [page, setPage] = useState(0);
  const cascadingFiltersLocalRef = useRef([]);

  return (
    <Stack direction="row" gap={2} m={3}>
      <Suspense fallback={<LoadingSpinner fullScreen />}>
        <Outlet context={{ page, setPage, isShowCascadingFilters, setIsShowCascadingFilters }} />
      </Suspense>
      {isShowCascadingFilters && (
        // TODO: need to find a cleaner way for this...
        <Box mt={-3} mr={-3}>
          <CascadingFilters cascadingFiltersLocalRef={cascadingFiltersLocalRef} setPage={setPage} />
        </Box>
      )}
    </Stack>
  );
};

export default CustomerRequests;
