import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, IconButton, Stack, Typography } from '@mui/material';
import { Add, Close, SettingsOutlined, OutboundOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { Notification } from 'contexts/Notification.context';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';
import { getBaseURL } from '../../utils/environment';
import NotificationCard from 'components/NotificationCard/NotificationCard';
import { Button } from 'cprime-foundational-components';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const ICON_BUTTON_COLOR = 'primary.main';

const NotificationSection = ({ title, notifications }) => {
  return (
    <>
      <Stack px={2.5} py={1.5} bgcolor="background.default">
        <Typography variant="body2">{title}</Typography>
      </Stack>
      <Stack>
        {notifications.length > 0 &&
          notifications.map((notification) => (
            <Stack
              key={notification.id}
              sx={{
                padding: 0,
                borderTop: '0.0625rem solid',
                borderBottom: '0.0625rem solid',
                borderColor: 'divider',
                margin: '-0.0625rem 0 0 -0.0625rem',
              }}
            >
              <NotificationCard
                id={notification.id}
                title={`New Update(s) in "${notification.title}"`}
                subtitle={notification.subtitle}
                type="info"
                isRead={notification.isRead}
                timestamp={notification.timestamp}
              />
            </Stack>
          ))}
      </Stack>
    </>
  );
};

NotificationSection.propTypes = {
  title: PropTypes.string.isRequired,
  notifications: PropTypes.array,
};

const NotificationsPanelView = () => {
  const [showReadMore, setShowReadMore] = useState(false);
  const scrollRef = useRef();

  const { open, notifications, setOpen, readAllNotifications } = useContext(Notification);

  useEffect(() => {
    if (open) {
      const handleScroll = () => {
        const scrollPosition = scrollRef.current?.scrollTop;

        if (scrollPosition === 0) {
          setShowReadMore(false);
        } else {
          setShowReadMore(true);
        }
      };

      scrollRef.current?.addEventListener('scroll', handleScroll);

      return () => {
        scrollRef.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [open]);

  const BASE_URL = getBaseURL();

  const hasUnread = notifications.some((notification) => !notification.isRead);
  const todaysNotifications = [];
  const yesterdaysNotifications = [];
  const olderNotifications = [];

  notifications.forEach((notification) => {
    const notificationDate = new Date(notification.timestamp);
    if (dayjs(notificationDate).isToday()) todaysNotifications.push(notification);
    else if (dayjs(notificationDate).isYesterday()) yesterdaysNotifications.push(notification);
    else olderNotifications.push(notification);
  });

  const handleClose = () => setOpen(false);

  const handleReadAllNotifications = () => {
    readAllNotifications();
  };

  return (
    <Drawer
      disablePortal
      anchor="right"
      open={open}
      sx={{
        top: '3.5rem',
        ['& .MuiBackdrop-root']: { backdropFilter: 'blur(0.25rem)', top: '3.5rem' },
        zIndex: 9999999,
      }}
      PaperProps={{
        sx: { width: { mobile: '100%', tablet: '34rem', top: '3.5rem' } },
      }}
      onClose={handleClose}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2.5}
          py={2}
          bgcolor="background.default"
        >
          <Typography variant="h3">Notifications</Typography>
          <Stack direction="row" spacing={1}>
            <TooltipWrapper tooltipText="Set New Notification" placement="left">
              <IconButton
                sx={{ color: ICON_BUTTON_COLOR }}
                onClick={() => {
                  window.location.href = `https://profile.${BASE_URL}/NotificationsCustomView`;
                  handleClose();
                }}
              >
                <Add />
              </IconButton>
            </TooltipWrapper>
            <TooltipWrapper tooltipText="Notification Settings" placement="left">
              <IconButton
                sx={{ color: ICON_BUTTON_COLOR }}
                onClick={() => {
                  window.location.href = `https://profile.${BASE_URL}/NotificationsCenterView`;
                  handleClose();
                }}
              >
                <SettingsOutlined />
              </IconButton>
            </TooltipWrapper>
            <IconButton sx={{ color: ICON_BUTTON_COLOR }} onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          ref={scrollRef}
          position="relative"
          maxHeight={showReadMore ? 'calc(100vh - 13.375rem)' : 'calc(100vh - 8rem)'}
          overflow="scroll"
        >
          {hasUnread && (
            <Button
              disableRipple
              sx={{
                position: 'absolute',
                right: '1rem',
                ':hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}
              onClick={handleReadAllNotifications}
            >
              <Typography color="info.main" variant="body2">
                Mark All as Read
              </Typography>
            </Button>
          )}
          {todaysNotifications.length > 0 && (
            <NotificationSection title="Today" notifications={todaysNotifications} />
          )}
          {yesterdaysNotifications.length > 0 && (
            <NotificationSection title="Yesterday" notifications={yesterdaysNotifications} />
          )}
          {olderNotifications.length > 0 && (
            <NotificationSection title="Older" notifications={olderNotifications} />
          )}
        </Stack>
        {showReadMore && (
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="background.default"
            height="5.375rem"
          >
            <Button
              color="primary"
              sx={{
                gap: 1,
                width: '13.6rem',
              }}
            >
              <Typography variant="body2">Read More Notifications</Typography>
              <OutboundOutlined fontSize="small" />
            </Button>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
};

export default NotificationsPanelView;
