import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

const EllipsisWithTooltip = ({
  children,
  tooltipText,
  isAlwaysShowTooltip = false,
  isEllipsisWithTooltip = true,
  isPrint = false,
}) => {
  const childRef = useRef(null);
  const [truncated, setTruncated] = useState(false);

  useEffect(() => {
    const checkIsTruncated = () => {
      if (childRef.current) {
        const clientWidth = childRef?.current?.getBoundingClientRect().width;
        childRef.current.style.overflow = 'visible';
        const contentWidth = childRef?.current?.getBoundingClientRect().width;
        childRef.current.style.overflow = 'hidden';

        if (clientWidth < contentWidth) {
          setTruncated(true);
        } else {
          setTruncated(false);
        }
      }
    };
    if (childRef.current?.offsetWidth && childRef.current?.scrollWidth) {
      checkIsTruncated();
    }

    window.addEventListener('resize', checkIsTruncated);
    return () => window.removeEventListener('resize', checkIsTruncated);
  }, [childRef]);

  if (!children || !isEllipsisWithTooltip || isPrint) {
    return children;
  }

  const refChildren = React.cloneElement(children, { ref: childRef });

  return truncated || isAlwaysShowTooltip ? (
    <TooltipWrapper tooltipText={tooltipText || refChildren} placement="bottom">
      {refChildren}
    </TooltipWrapper>
  ) : (
    refChildren
  );
};

EllipsisWithTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isAlwaysShowTooltip: PropTypes.bool,
  isEllipsisWithTooltip: PropTypes.bool,
  isPrint: PropTypes.bool,
};

export default EllipsisWithTooltip;
