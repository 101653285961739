import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Paper, Typography } from '@mui/material';
import * as S from './ErrorCard.styles';
import CubeError from '../../assets/CubeError.svg';

const ErrorCard = ({ message, details, subDetails }) => (
  <Container disableGutters maxWidth="tablet">
    <Paper elevation={0}>
      <Grid container p={2}>
        <Grid container flexDirection="column" justifyContent="center" tablet={6}>
          <Typography variant="display1" component="h2" mb={4} mt={2}>
            Cube
          </Typography>
          <Typography variant="h4" component="p" mb={3}>
            {message}
          </Typography>
          <Typography variant="h6" component="p" mb={1}>
            {details}
          </Typography>
          <Typography variant="h6" component="p" color="text.disabled" mb={2}>
            {subDetails}
          </Typography>
        </Grid>

        <Grid item tablet={6}>
          <S.StyledImage src={CubeError} />
        </Grid>
      </Grid>
    </Paper>
  </Container>
);

ErrorCard.propTypes = {
  message: PropTypes.string,
  details: PropTypes.string,
  subDetails: PropTypes.string,
};

export default ErrorCard;
