import styled from '@emotion/styled';
import { ButtonBase } from '@mui/material';

export const StyledIconButton = styled(ButtonBase)`
  background: ${({ active, theme }) => (active ? theme.palette.secondary.main : 'transparent')};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;
  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`;
