import { useLocation } from 'react-router-dom';

const navPropMap = new Map([
  ['/', { subnavTitle: 'Pipeline', disableSubnavItems: false }],
  ['/vehicle', { subnavTitle: null, disableSubnavItems: false }],
  ['/customer-requests', { subnavTitle: 'Pipeline', disableSubnavItems: false }],
  ['/bookmarks', { subnavTitle: 'Bookmarks', disableSubnavItems: true }],
]);

// Takes the first segment. If it's empty, the route is '/'
export const getFirstSegment = (pathname) => {
  const [firstSegment] = pathname.split('/').filter(Boolean);

  return firstSegment ? `/${firstSegment}` : '/';
};

export const usePathSegments = () => {
  const { pathname } = useLocation();

  return navPropMap.get(getFirstSegment(pathname));
};
