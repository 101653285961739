import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'cubedist-cube-pipeline-common-ui';
import { useAuth } from 'react-oidc-context';
import {
  BookmarkBorder as BookmarkBorderIcon,
  NotificationsNoneOutlined as NotificationsNoneOutlinedIcon,
} from '@mui/icons-material';
import { Badge } from '@mui/material';
import { FeatureFlags } from 'contexts/Features.context';
import { Notification } from 'contexts/Notification.context';
import { Roles } from 'contexts/Roles.context';
import { useCustomNavigate } from 'hooks';
import * as S from './NavBar.styles';

const NavBar = ({ navProps }) => {
  const auth = useAuth();

  const { features } = useContext(FeatureFlags);
  const { permissionsExist } = useContext(Roles);
  const { open, setOpen, unreadNotifications, setUnreadNotifications } = useContext(Notification);

  const navigate = useCustomNavigate();

  const isAdmin = permissionsExist(['Admin']);

  const handleOpenNotifications = () => {
    setOpen(!open);
    setUnreadNotifications(false);
  };

  return (
    <Navigation
      app="pipeline"
      disableSearch={!features?.globalSearchEnabled}
      disableSubnavItems={navProps?.disableSubnavItems ?? false}
      subnavTitle={
        navProps?.subnavTitle || navProps?.subnavTitle === null ? navProps.subnavTitle : 'Pipeline'
      }
      isAdmin={isAdmin}
      handleLogout={() => auth.signoutRedirect()}
      rightSlot={
        <>
          {features?.bookmarksEnabled && (
            <S.StyledIconButton onClick={() => navigate('/bookmarks')}>
              <BookmarkBorderIcon />
            </S.StyledIconButton>
          )}
          {features?.notifications && (
            <S.StyledIconButton onClick={handleOpenNotifications}>
              <Badge
                variant="dot"
                color="error"
                overlap="circular"
                invisible={!unreadNotifications}
              >
                <NotificationsNoneOutlinedIcon />
              </Badge>
            </S.StyledIconButton>
          )}
        </>
      }
    />
  );
};

NavBar.propTypes = {
  navProps: PropTypes.shape({
    subnavTitle: PropTypes.string,
    disableSubnavItems: PropTypes.bool,
  }),
};

export default NavBar;
