import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useQuery } from '@apollo/client';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { GET_FEATURE_FLAGS } from 'queries/FeatureFlags';
import { getEnv } from '../utils/environment';

export const FeatureFlags = createContext({});

const environment = getEnv();

const arrayToObject = (featureArr) => {
  const result = {};

  if (!featureArr) {
    return result;
  }

  featureArr.forEach((feature) => {
    result[feature.name] = !isNil(feature.enabled) ? feature.enabled : JSON.parse(feature.fields);
  });

  return result;
};

const FeaturesProvider = ({ children }) => {
  const { data, loading } = useQuery(GET_FEATURE_FLAGS);
  const features = arrayToObject(data?.FeatureFlags);
  const contextValue = useMemo(() => ({ environment, features }), [features]);
  if (loading) {
    return <LoadingSpinner fullScreen />;
  }
  return <FeatureFlags.Provider value={contextValue}>{children}</FeatureFlags.Provider>;
};

FeaturesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeaturesProvider;
