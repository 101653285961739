import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ToastNotification from '../components/ToastNotification/ToastNotification';

const NOTIFICATION_TITLES = [
  'ETA Status Change on Auto Show Vehicles',
  'Toledo Auto Show',
  'DFW Vehicle Holds',
  'DFW Railhead Holds',
];

const NOTIFICATION_SUBTITLES = [
  '13 VINs affected. Please view lorem ipsum dolar lorem ipsum dolar.',
  '2 VINs affected. Please view lorem ipsum dolar lorem ipsum dolar.',
  '23 VINs affected. Please view lorem ipsum dolar lorem ipsum dolar.',
];

export const Notification = createContext({});

const NotificationProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [notification, setNotification] = useState({});
  const show = notification && !!Object.values(notification)?.length;

  useEffect(() => {
    // mock data
    const notificationsData = [
      {
        title: NOTIFICATION_TITLES[0],
        subtitle: NOTIFICATION_SUBTITLES[0],
        timestamp: Date.now() - 760000,
        isRead: false,
        id: 1,
      },
      {
        title: NOTIFICATION_TITLES[1],
        subtitle: NOTIFICATION_SUBTITLES[1],
        timestamp: Date.now() - 1000000,
        isRead: false,
        id: 2,
      },
      {
        title: NOTIFICATION_TITLES[2],
        subtitle: NOTIFICATION_SUBTITLES[2],
        timestamp: Date.now() - 2480000,
        isRead: false,
        id: 3,
      },
      {
        title: NOTIFICATION_TITLES[3],
        subtitle: NOTIFICATION_SUBTITLES[2],
        timestamp: Date.now() - 100000000,
        isRead: false,
        id: 4,
      },
      {
        title: NOTIFICATION_TITLES[3],
        subtitle: NOTIFICATION_SUBTITLES[2],
        timestamp: Date.now() - 130000000,
        isRead: false,
        id: 5,
      },
      {
        title: NOTIFICATION_TITLES[3],
        subtitle: NOTIFICATION_SUBTITLES[2],
        timestamp: Date.now() - 160000000,
        isRead: false,
        id: 6,
      },
      {
        title: NOTIFICATION_TITLES[1],
        subtitle: NOTIFICATION_SUBTITLES[1],
        timestamp: Date.now() - 230000000,
        isRead: false,
        id: 7,
      },
      {
        title: NOTIFICATION_TITLES[1],
        subtitle: NOTIFICATION_SUBTITLES[1],
        timestamp: Date.now() - 430000000,
        isRead: false,
        id: 8,
      },
      {
        title: NOTIFICATION_TITLES[1],
        subtitle: NOTIFICATION_SUBTITLES[1],
        timestamp: Date.now() - 1030000000,
        isRead: false,
        id: 9,
      },
      {
        title: NOTIFICATION_TITLES[1],
        subtitle: NOTIFICATION_SUBTITLES[1],
        timestamp: Date.now() - 1530000000,
        isRead: false,
        id: 10,
      },
    ];
    setNotifications(notificationsData);
    setUnreadNotifications(notificationsData.some((notification) => !notification.isRead));
  }, []);

  useEffect(() => {
    const timer = show && setTimeout(() => setNotification({}), notification?.timeout || 10000);
    return () => clearTimeout(timer);
  }, [notification]);

  const readAllNotifications = () => {
    setNotifications(notifications.map((notification) => ({ ...notification, isRead: true })));
  };

  const readNotification = (id) => {
    setNotifications(
      notifications.map((notification) => {
        if (notification.id === id) {
          return {
            ...notification,
            isRead: true,
          };
        }
        return notification;
      }),
    );
  };

  const unreadNotification = (id) => {
    setNotifications(
      notifications.map((notification) => {
        if (notification.id === id) {
          return {
            ...notification,
            isRead: false,
          };
        }
        return notification;
      }),
    );
  };

  const dispatchNotification = useCallback(
    (notificationProps) => setNotification(notificationProps),
    [],
  );

  const contextValue = useMemo(
    () => ({
      open,
      notifications,
      unreadNotifications,
      setOpen,
      setUnreadNotifications,
      readAllNotifications,
      readNotification,
      unreadNotification,
      dispatchNotification,
    }),
    [
      open,
      notifications,
      unreadNotifications,
      setOpen,
      setUnreadNotifications,
      readAllNotifications,
      readNotification,
      unreadNotification,
      dispatchNotification,
    ],
  );

  const mapStyles = () => {
    switch (notification?.position) {
      case 'bottom':
        return {
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          pointerEvents: 'none',
        };
      case 'top':
        return {
          top: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          pointerEvents: 'none',
        };
      case 'bottom-right':
        return { bottom: 0, right: 0 };
      case 'bottom-left':
        return { bottom: 0, left: 0 };
      case 'top-left':
        return { top: 0, left: 0 };
      case 'top-right':
        return { top: 0, right: 0 };
      default:
        return {};
    }
  };

  return (
    <Notification.Provider value={contextValue}>
      {children}
      {show && (
        <Box m="2rem" zIndex="10000" position="fixed" sx={mapStyles()}>
          <Box sx={{ pointerEvents: 'all' }}>
            <ToastNotification open onClose={() => setNotification({})} {...notification} />
          </Box>
        </Box>
      )}
    </Notification.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
