import React from 'react';
import PropTypes from 'prop-types';
import { CascadingFiltersList } from './CascadingFiltersList';
import { CascadingFiltersHeader } from './CascadingFiltersHeader';

export const CascadingFiltersSection = ({
  headerName,
  options,
  setCascadingFilters,
  cascadingFilters,
  setOpenSection,
  openSection,
  setCascadingFiltersLocal,
  cascadingFiltersLocal,
  cascadingFiltersConfig,
  isCascadingFilterOff,
}) => (
  <CascadingFiltersHeader
    headerName={headerName}
    options={options}
    setCascadingFilters={setCascadingFilters}
    cascadingFilters={cascadingFilters}
    setOpenSection={setOpenSection}
    openSection={openSection}
    setCascadingFiltersLocal={setCascadingFiltersLocal}
    cascadingFiltersLocal={cascadingFiltersLocal}
  >
    <CascadingFiltersList
      headerName={headerName}
      options={options}
      setCascadingFilters={setCascadingFilters}
      cascadingFilters={cascadingFilters}
      setCascadingFiltersLocal={setCascadingFiltersLocal}
      cascadingFiltersLocal={cascadingFiltersLocal}
      cascadingFiltersConfig={cascadingFiltersConfig}
      isCascadingFilterOff={isCascadingFilterOff}
    />
  </CascadingFiltersHeader>
);

CascadingFiltersSection.propTypes = {
  headerName: PropTypes.string,
  options: PropTypes.array,
  setCascadingFilters: PropTypes.func,
  cascadingFilters: PropTypes.array,
  setOpenSection: PropTypes.func,
  openSection: PropTypes.string,
  setCascadingFiltersLocal: PropTypes.func,
  cascadingFiltersLocal: PropTypes.array,
  cascadingFiltersConfig: PropTypes.array,
  isCascadingFilterOff: PropTypes.bool,
};
